<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Danh sách các doanh nghiệp đã tự công bố đủ điều kiện huấn luyện hạng A.'"
    />
    <!-- End Hero -->

    <main
      id="main"
    >
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div
          id="legal-document"
          class="container"
        >
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="20"
              color="#0282CD"
            />
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div
              class="titlePage"
              style="color: #000000"
            >
              Tra cứu
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div
              class="titlePage"
            >
              Danh sách các doanh nghiệp đã tự công bố đủ điều kiện huấn luyện hạng A.
            </div>
          </div>
          <div
            class="mt-1"
          >
            <b-row
              class="d-flex justify-content-end mb-1"
            >
              <b-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="d-flex justify-content-end mb-1"
              >
                <search
                  class="search-input"
                  @change="search"
                />
              </b-col>

            </b-row>

            <div
              id="users"
            >
              <vue-good-table
                ref="user-table"
                style-class="vgt-table"
                :columns="columns"
                :rows="dataList || []"
                :select-options="{
                  enabled: false,
                  vertialAlignTop: true,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }"
              >
                <!-- Slot: Table Row -->
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: số hiệu văn bản-->
                  <span v-if="props.column.field === 'dateSelfClare'">
                    {{ props.row.dateSelfClare |formatDateToDDMM }}
                  </span>

                  <!-- Column:Chức năng -->
                  <span v-else-if="props.column.field === 'ActionFunction'">
                    <span
                      class="ml-2"
                      @click="showModalSeePdf(props.row.id)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="'Xem file'"
                        icon="EyeIcon"
                        size="18"
                        class="text-body"
                      />
                    </span>
                  </span>
                </template>
              </vue-good-table>

              <!-- pagination -->
              <my-pagination
                :totalItems="totalRecord"
                :currentPage="urlQuery.pageNumber"
                @pageClick="pageChange"
              />

              <ModalSeePdf
                :id="modaIdSee"
                :pdfsrc="pdfsrc"
                @downloadFilePdf="downloadFilePdf"
              />

            </div>

          </div>
        </div>
      </section>
      <!-- End Team Section -->
    </main>

    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />

    </footer>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BCol,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalSeePdf from '@/views/occupational-safety-health/condition-department/pages/components/ModalSeePdf.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import Carousel from './components/Carousel.vue'
import SiteFooter from './components/SiteFooter.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'
import SiteHeader from './components/SiteHeader.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    Search,
    BCol,
    BRow,
    SiteHeader,
    Carousel,
    SiteFooter,
    ModalSeePdf,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      columns: [
        {
          label: 'DOANH  NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'NGÀY CÔNG BỐ',
          field: 'dateSelfClare',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      modaIdSee: 'modal-seed-pdf-condition',
      dataList: [],
      pdfsrc: '',
      selfDeclareTrainingId: '',
    }
  },

  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      }
      return document.querySelector(el)
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = el => {
      const header = select('#header')
      let offset = header.offsetHeight

      if (!header.classList.contains('header-scrolled')) {
        offset -= 20
      }

      const elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    const backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on('click', '.mobile-nav-toggle', function (e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.navbar .dropdown > a',
      // eslint-disable-next-line func-names
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      },
      true,
    )

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.scrollto',
      // eslint-disable-next-line func-names
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          const navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            const navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true,
    )

    /**
     * Skills animation
     */
    const skilsContent = select('.skills-content')
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: '80%',
        handler(direction) {
          const progress = select('.progress .progress-bar', true)
          progress.forEach(el => {
            el.style.width = `${el.getAttribute('aria-valuenow')}%`
          })
        },
      })
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper('.testimonials-carousel', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
      const portfolioContainer = select('.portfolio-container')
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.portfolio-wrap',
          layoutMode: 'fitRows',
        })

        const portfolioFilters = select('#portfolio-flters li', true)

        // eslint-disable-next-line func-names
        on(
          'click',
          '#portfolio-flters li',
          // eslint-disable-next-line func-names
          function (e) {
            e.preventDefault()
            portfolioFilters.forEach(el => {
              el.classList.remove('filter-active')
            })
            this.classList.add('filter-active')

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter'),
            })
            portfolioIsotope.on('arrangeComplete', () => {
              // eslint-disable-next-line no-undef
              AOS.refresh()
            })
          },
          true,
        )
      }
    })

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: '.portfolio-lightbox',
    })

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false,
      })
    })
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    showModalSeePdf(selfDeclareTrainingId) {
      this.selfDeclareTrainingId = selfDeclareTrainingId
      axiosApiInstance({
        url: ConstantsApi.FILE_SELF_DECLARE_TRAINING,
        method: 'GET',
        responseType: 'blob',
        params: { selfDeclareTrainingId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modaIdSee)
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SELF_DECLARE_TRAINING, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    downloadFilePdf() {
      axiosApiInstance({
        url: ConstantsApi.FILE_SELF_DECLARE_TRAINING,
        method: 'GET',
        responseType: 'blob',
        params: { selfDeclareTrainingId: this.selfDeclareTrainingId },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'GiayThongBaoDuDieuKienHuanLuyenHangA.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

#legal-document {
  .vgt-table {
    min-width: max-content;
    th {
      background-color: #0282CD;
      color: #fff !important;
    }
  }
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }

  .vSelcetHome {
    width: 40%;
  }

  @media (max-width: 768px) {
    .vSelcetHome {
      width: 100%;
    }
  }
}
</style>
